<template lang="pug">
modal-card(title='Crear un sitio' width='500px')
  app-form(@submit.prevent='submit' :is-saving='saving' autocomplete='off')
    text-field(label='Nombre' placeholder='Ejm: Puente XYZ' :maxlength='30' required v-model='location.name')
    .icons.mb-5
      .image-container
        img.location-icon(
          v-for='icon in markerImages'
          :src='`img/marker-images/${icon}.png`' alt='marker image'
          :class='{ selected: icon === location.icon}'
          @click='selectIcon(icon)'
        )
</template>

<script>
import toastService from '@/services/toast.service'
import markerImages from './marker-images'

export default {
  props: {
    latLng: { type: Object },
    locationUpdated: { type: Object }
  },
  data () {
    return {
      markerImages,
      saving: false,
      location: this.locationUpdated ? { ...this.locationUpdated } : { icon: 0 }
    }
  },
  methods: {
    selectIcon (icon) {
      this.location.icon = icon
    },
    async submit () {
      const location = {
        ...this.location,
        ...(this.latLng ? { lat: this.latLng.lat, lng: this.latLng.lng } : {})
      }
      this.saving = true
      const result = await this.$store.dispatch('locations/saveLocation', location)
      if (result) {
        toastService.show('Sitio guardado')
        this.$parent.close()
      }
      this.saving = false
    }
  }
}
</script>

<style lang="scss" scoped>

.icons {
  max-height: 200px;
  overflow-y: auto;
}

.image-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  .location-icon {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  .selected {
    border: solid 1px #171717;
    opacity: 1;
  }
}
</style>
